.overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* implement media queries for the width */
.content {
  background-color: white;
  border-radius: 0.25rem;
  width: 20%;
  padding: 2rem;
  position: relative;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
  .content {
    width: 20%;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .content {
    width: 40%;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .content {
    width: 50%;
  }
}

@media all and (max-width: 480px) {
  .content {
    width: 60%;
  }
}
